import React, { useState } from 'react';

import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from 'assets/img/logo_inout.png'


//global.localip = window.location.hostname;
global.localip = "gsp.ecknology.de";
//Worktime Initialise
var worktimesdata = [] //Array with Objects with all Worktimes with Userids


global.currentHolidayA = function (userid) {
  var worktimeobject = global.currentWorktimeObject(userid);
  if(worktimeobject == undefined){
    return 0;
  } else {
    return parseInt(worktimeobject[worktimeobject.length-1].holidays);
  }
}

global.HolidayAAt = function (date, userid) {
  var worktimeobject = global.WorktimeObjectAt(date, userid);
  if(worktimeobject == undefined){
    return 0;
  } else {
    return parseInt(worktimeobject[worktimeobject.length-1].holidays);
  }
}

global.currentWorkTimesData = function (userid) {
  var found = false;
  for (let i = 0; i < worktimesdata.length; i++) {
    const element = worktimesdata[i];
    if (element.userid == userid) {
      found = true;
      return element.data[0];
    }
  }
  if (!found) {

    global.fetchWorktimesbyUserid(userid);
    return undefined;
  }
}

global.WorktimesDataAt = function (date, userid) {
  var found = false;
  for (let i = 0; i < worktimesdata.length; i++) {
    const element = worktimesdata[i];

    if (element.userid == userid) {
      var data = element.data;

      for (let j = 0; j < data.length; j++) {
        const dataelement = data[j];


        if (new Date(dataelement.inserted_at) < date) {
          found = true;
          return dataelement;
        }
        if (j == data.length - 1) {
          if (!found && data.length > 0) {
            return dataelement;
          }
        }
      }

    }
  }
  if (!found) {
    global.fetchWorktimesbyUserid(userid);
    return undefined;
  }
}

global.currentWorktimeObject = function (userid) {
  var found = false;
  for (let i = 0; i < worktimesdata.length; i++) {
    const element = worktimesdata[i];
    if (element.userid == userid) {
      found = true;
      return JSON.parse(element.data[0].worktimeobject);
    }
  }
  if (!found) {
    global.fetchWorktimesbyUserid(userid);
    return undefined;
  }
}

global.WorktimeObjectAt = function (date, userid) {
  var found = false;
  for (let i = 0; i < worktimesdata.length; i++) {
    const element = worktimesdata[i];
    if (element.userid == userid) {
      var data = element.data;
      for (let j = 0; j < data.length; j++) {
        const dataelement = data[j];


        if (new Date(dataelement.inserted_at) < date) {

          found = true;
          return JSON.parse(dataelement.worktimeobject);
        }
        if (j == data.length - 1) {
          if (!found && data.length > 0) {
            return JSON.parse(dataelement.worktimeobject);
          }
        }
      }

    }
  }
  if (!found) {
    global.fetchWorktimesbyUserid(userid);
    return undefined;
  }
}

global.currentWorkingDays = function (userid) {
  var found = false;
  for (let i = 0; i < worktimesdata.length; i++) {
    const element = worktimesdata[i];
    if (element.userid == userid) {
      found = true;

      var workingdays = [];

      for (let i = 0; i < JSON.parse(element.data[0].worktimeobject).length - 2; i++) {
        const element2 = JSON.parse(element.data[0].worktimeobject)[i];

        workingdays.push(parseInt(element2.day));
      }

      return workingdays;
    }
  }
  if (!found) {
    global.fetchWorktimesbyUserid(userid);
    return undefined;
  }
}

global.WorkingDaysAt = function (date, userid) {
  var found = false;
  for (let i = 0; i < worktimesdata.length; i++) {
    const element = worktimesdata[i];

    if (element.userid == userid) {
      var data = element.data;

      for (let j = 0; j < data.length; j++) {
        const dataelement = data[j];


        if (new Date(dataelement.inserted_at) < date) {

          found = true;
          var workingdays = [];
          for (let i = 0; i < JSON.parse(dataelement.worktimeobject).length - 2; i++) {
            const element = JSON.parse(dataelement.worktimeobject)[i];

            workingdays.push(parseInt(element.day));
          }

          return workingdays;
        }
        if (j == data.length - 1) {
          if (!found && data.length > 0) {

            var workingdays = [];
            for (let i = 0; i < JSON.parse(dataelement.worktimeobject).length - 2; i++) {
              const element = JSON.parse(dataelement.worktimeobject)[i];
  
              workingdays.push(parseInt(element.day));
            }
  
            return workingdays;
          }
        }
      }
    }
  }
  if (!found) {
    global.fetchWorktimesbyUserid(userid);
    return undefined;
  }
}



global.fetchWorktimesbyUserid = function (userid) {

  let url = 'https://' + global.localip + ':3005/getWorktimesbyId/' + userid;
  fetch(url)
    .then(response => response.json())
    .then(result2 => {
      setWorktimesbyUserid(userid, result2);
    })
}

function setWorktimesbyUserid(userid, data) {
  for (let i = 0; i < worktimesdata.length; i++) {
    const element = worktimesdata[i];
    if (element.userid === userid) {
      element.data = data;
      return;
    }
  }
  var obj = {};
  obj.userid = userid;
  obj.data = data;
  worktimesdata.push(obj);
}
global.currentWorkTimesData(1009);







let ps;


const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/login/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  var generatedRoutes = [];
  if (window.loggedinadmin == 1) {
    generatedRoutes.push(routes[2]);
    generatedRoutes.push(routes[3]);
    generatedRoutes.push(routes[4]);
    generatedRoutes.push(routes[5]);
    generatedRoutes.push(routes[6]);
    generatedRoutes.push(routes[8]);
    generatedRoutes.push(routes[10]);
    generatedRoutes.push(routes[11]);
    generatedRoutes.push(routes[0]);
  } else {
    generatedRoutes.push(routes[1]);
    generatedRoutes.push(routes[2]);
    generatedRoutes.push(routes[3]);
    generatedRoutes.push(routes[9]);
    generatedRoutes.push(routes[0]);
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={generatedRoutes}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"green"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={generatedRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}


      </div>
    </div>
  );
}

